import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersOnlyDirective} from './numbersOnly.directive';

@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      NumbersOnlyDirective
   ],
   exports: [
      NumbersOnlyDirective
   ]
})
export class DirectiveModule { }
