import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constant } from '../../environments/constant';
import { Observable } from 'rxjs';
import { Utils } from 'src/utils/utils';
import * as model from '../models/models';
// import 'rxjs/add/observable/of';
// import 'rxjs/add/operator/delay';

@Injectable({
  providedIn: 'root'
})
export class NewAccountsService {

  constructor(private _httpClient: HttpClient) { }

  getNewAccounts(payload: model.SearchCriteria): Observable<model.NewAccountsResponse> {
    return this._httpClient.post<model.NewAccountsResponse>(Constant.uri.getNewAccounts, payload, Utils.getHttpOptions());
  }

  getFacets(payload: model.SearchCriteria): Observable<model.NewAccountsResponse> {
    return this._httpClient.post<model.NewAccountsResponse>(Constant.uri.getNewAccounts, payload, Utils.getHttpOptions());
  }

}

