import { HttpHeaders } from '@angular/common/http';
import { Constant } from 'src/environments/constant';
export class Utils {
   
  static corelids: any = {"REBAL":"","OPTANL":"","SAVEORDER":"","FREETRADE":""};
  public static calcdate(daydiff: number, sourcedt?: Date): Date {
    let retDt = new Date();
    if (sourcedt) {
      retDt = sourcedt;
    }
    if (daydiff) {
      retDt.setDate(retDt.getDate() - daydiff);
    }
    return retDt;
  }

  public static calcAge(dob:any){
    let age = 0;
    const _today = new Date();
    const _bd = new Date(dob);
    age = _today.getFullYear() - _bd.getFullYear();
    let _m = _today.getMonth() - _bd.getMonth();
    if(_m < 0 || (_m === 0 && _today.getDate() < _bd.getDate())){
      age--;
    }
    return age;
  }
  public static getProductDesc(c:string){
    let retVal =c;
    if(c){
      retVal = Constant.productTypes.find(t => t.code === c)?.value||c;
    }
    return retVal;
  }
  public static applyOrderBy(data: any, orderBy: string, field: string, isNumber = false): any {
    let tempPositions = JSON.parse(JSON.stringify(data));
    tempPositions.sort((p1:any, p2:any) => {
      const _p1 = p1[field] ? p1[field] : 0;
      const _p2 = p2[field] ? p2[field] : 0;
      if (isNumber) {
        if (orderBy === "ASC") {
          return parseFloat(_p2) - parseFloat(_p1);
        }
        else {
          return parseFloat(_p1) - parseFloat(_p2);
        }
      }
      if (orderBy === "ASC") {
        if (_p1 > _p2) {
          return 1;
        }
        if (_p1 < _p2) {
          return -1;
        }
        return 0;
      } else {
        if (_p1 > _p2) {
          return -1;
        }
        if (_p1 < _p2) {
          return 1;
        }

        return 0;
      }
    });
    return tempPositions;
  }

  public static multiplesort(collection:any,sortBy:any[]){
    return collection.sort(function (a:any, b:any) {
      let i = 0, result = 0;
      while (i < sortBy.length && result === 0) {
        let _a = null;
        let _b = null;
        if (sortBy[i].number) {
          _a = a[sortBy[i].prop] ? a[sortBy[i].prop] : 0;
          _b = b[sortBy[i].prop] ? b[sortBy[i].prop] : 0;
          result = sortBy[i].direction * (parseFloat(_a) - parseFloat(_b));
        } else {
          _a = a[sortBy[i].prop] ? a[sortBy[i].prop].toString() : '';
          _b = b[sortBy[i].prop] ? b[sortBy[i].prop].toString() : '';
          result = sortBy[i].direction * (_a < _b ? -1 : (_a > _b ? 1 : 0));
        }
        i++;
      }
      return result;
    });
  }

  public static getcolortag():string{
  //   return ['#B3E3F4','#C4E1B2','#EF4264','#B02C91','#F4B93F','#13A5B0','#7BC46C','#B71F3A','#653C93','#F89938','#45BEAA',
  // '#57C8E7','#7BC46C','#B3E3F4','#C4E1B2'];
    let _hex =  '#' + Math.floor(Math.random() * 16777215).toString(16);    
    if(_hex.length != 7){      
      _hex =  '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
    return _hex;
  }
  
  public static getHttpOptions(corelationfrom:string = "", corelatedto:string = "") {
    const JWT = 'Bearer ' + sessionStorage.getItem('msal.idtoken');
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin':'*',
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
        'RCM-PM-GUID':this.newGuid(corelationfrom, corelatedto)
        //'Authorization': JWT,
      })
    };    
    return httpOptions;
  }

  public static getHttpOptionsformdata(corelationfrom:string = "",corelatedto:string = "") {
    const JWT = 'Bearer ' + sessionStorage.getItem('msal.idtoken');
    const httpOptions = {
      headers: new HttpHeaders({        
        'Access-Control-Allow-Origin':'*',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'RCM-PM-GUID':this.newGuid(corelationfrom, corelatedto)
        //'Authorization': JWT,
      })
    };    
    return httpOptions;
  }
  
  public static newGuid(corelationfrom:string = "",corelatedto:string = "", ) {
    if(corelatedto && this.corelids[corelatedto]){
      return this.corelids[corelatedto]
    }
    const _guid =  'PMUI-' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    if(corelationfrom){
      this.corelids[corelationfrom] = _guid;
    }
    return _guid;
  }

  public static generateRandomInteger(min:number, max:number) {
    return Math.floor(min + Math.random()*(max + 1 - min))
  }
}
