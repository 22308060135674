import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Constant } from './../environments/constant';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [  
  { 
    path: Constant.routes.existingorders, 
    canActivate:[MsalGuard],
    loadChildren: () => import('./existing-orders-v2/existing-orders-v2.module').then(m => m.ExistingOrdersV2Module) 
  },  
  {
    path: 'code',    
    redirectTo: Constant.routes.existingorders,
    pathMatch: 'full'
  },    
  {
    path: '',    
    pathMatch: 'full',
    redirectTo: Constant.routes.home
  },
  {
    path: Constant.routes.home,    
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: Constant.routes.newAccounts,    
    canActivate:[MsalGuard],
    loadChildren: () => import('./new-accounts/new-accounts.module').then(m => m.NewAccountsModule)  
  },
  {
    path: Constant.routes.pteRollover,    
    canActivate:[MsalGuard],
    loadChildren: () => import('./pte-rollover/pte-rollover.module').then(m => m.PTERolloverModule)  
  },
  {
    path: Constant.routes.existingordersV2,    
    canActivate:[MsalGuard],
    loadChildren: () => import('./existing-orders-v2/existing-orders-v2.module').then(m => m.ExistingOrdersV2Module)  
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
