import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LayoutModule } from '@angular/cdk/layout';


import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { InitService } from './services/init.service';
import { Constant } from 'src/environments/constant';
import { UserService } from './services/user.service';
import { NewAccountsService } from './services/new-accounts.service';
import { PTERolloverService } from './services/pte-rollover.service';
import { WorkflowService } from './services/workflow.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ExcelExportService } from './services/excel-export.service';
import { AdvTreeFilterComponent } from './advTreeFilter/advTreeFilter.component';
import { ExistingOrderService } from './services/existing-order.service';
import { Title } from '@angular/platform-browser';
import { ProcessAttestationModule } from './process-attestation/process-attestation.module';
import { ProcessOrdersModule } from './existing-orders-v2/process-orders/process-orders.module';

export function loggerCallback(logLevel: any, message: string) {
  console.log(message);
}

export function initApp(initsvc: InitService) {
  return (): Promise<any> => {
    if (window.location.href.indexOf("4200") > 0) {
      //return initsvc.getConfig();
      return initsvc.getConfigLocal();
    } else {
      //return initsvc.getConfigLocal();
      return initsvc.getConfig();
    }
  }
}

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export const _protectedResourceMap: [string, string[]][] = [[Constant.config.apiBaseUrl, Constant.config.scopes]];

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: Constant.config.clientId,
      //redirectUri: window.location.origin + "/signin-oidc",
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      authority: Constant.config.instance + Constant.config.tenant,
      knownAuthorities: [Constant.config.instance],
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: true
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  let protectedResourceMap = new Map<string, Array<string>>();
  //protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  //protectedResourceMap.set('https://login.microsoftonline.com/',['user.read',Constant.config.scopes])
  protectedResourceMap.set(Constant.config.apiBaseUrl, Constant.config.scopes);
  protectedResourceMap.set(Constant.config.apiBaseUrl2, Constant.config.scopes);
  protectedResourceMap.set(Constant.config.apiEnvUrl, Constant.config.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect, authRequest: { scopes: Constant.config.scopes } };
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogComponent,
    AdvTreeFilterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatRippleModule,
    MatDialogModule,
    MatDividerModule,
    MatSelectModule,
    MatRadioModule,
    MatTreeModule,
    MatCheckboxModule,
    MsalModule,
    MatExpansionModule,
    ProcessAttestationModule,
    ProcessOrdersModule

  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [InitService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    UserService,
    ExistingOrderService,
    ExcelExportService,
    NewAccountsService,
    Title,
    PTERolloverService,
    WorkflowService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
