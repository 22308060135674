import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Constant } from '../../environments/constant';
import * as fromModel from '../models/models';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'src/utils/utils';
import { Observable, BehaviorSubject, of } from 'rxjs';

export class UserDetail {
  name = '';
  userid = '';
  poolIds = '';
  repId = '';
  simulateRepIds = '';
  role = fromModel.USERROLE.FA;
}
@Injectable({
  providedIn: 'root'
})
export class UserService {

  appInsights: ApplicationInsights = new ApplicationInsights({ config: { instrumentationKey: Constant.config.appInsightKey } });

  private sessionId = '';
  private app: string = 'RegBI Tool -> ';
  private step = 0;
  private user: UserDetail | undefined;
  private faReviewChkList: fromModel.ReviewChkItem[] = [];
  public userSubject = new BehaviorSubject<UserDetail | undefined>(undefined);
  public spinnerState = new BehaviorSubject<boolean>(false);
  constructor(private _httpClient: HttpClient,
    private route: ActivatedRoute
  ) {
    this.sessionId = (new Date()).getTime().toString();
    this.app += ' Session Id:' + this.sessionId + ' ';
    this.setReviewChkList();
  }

  //{code:'C3', desc:'The client does not need the liquidity for any near-term expenses', products:['AI','SP','AN','PREF'], buySellCode:'B'},
  private setReviewChkList() {
    this.faReviewChkList = [
      { code: 'C1', desc: 'The investment is part of a larger investment allocation that is appropriate for the client’s overall investment profile', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'B' },
      { code: 'C2', desc: 'The client desire for returns is aligned with the risk of the investment', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'B' },
      { code: 'C4', desc: 'The allocation to this investment is in-line with the client’s overall time horizon', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'B' },
      { code: 'C5', desc: 'The client has invested in similar products or strategies in the past and is seeking to increase that exposure', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'B' },
      { code: 'C6', desc: 'The account type in which the transaction occurred', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'B' },
      { code: 'S1', desc: 'Material change to current market v. that of prior capital market assumptions', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'S' },
      { code: 'S2', desc: 'Material change to Issuer / Manager', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'S' },
      { code: 'S3', desc: 'Reducing exposure to asset class, strategy or Issuer (diversification)', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'S' },
      { code: 'S4', desc: 'Client needs liquidity / raise capital', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'S' },
      { code: 'S5', desc: 'Take/Recognize profits', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'S' },
      { code: 'S6', desc: 'Take/Recognize losses', products: ['AI', 'SP', 'AN', 'PREF'], buySellCode: 'S' }
    ];
  }
  setUser(u: UserDetail) {
    this.user = u;
    this.userSubject.next(u);
  }


  getUser(): UserDetail | undefined {
    return this.user;
  }

  getAdviorsInfo(clientAdvId: string): Observable<any> {
    //clientAdvId = '536';  
    const _url = Constant.uri.advSearch + "?queryType=full&select=HubName,BusinessSegment,ClientAdvisorTeam,RegionName,PoolFlag,ClientAdvisorID,id,rid&$filter=ClientAdvisorID eq '" + clientAdvId + "'";
    return this._httpClient.get(_url, Utils.getHttpOptions());
  }
  getAdvisors(bmEmail: string, isFA: boolean): Observable<fromModel.AdvisorHirerarchy> {
    //return of(_advisors).delay(2000);
    if (isFA) {
      return (of(new fromModel.AdvisorHirerarchy()));
    }
    return this._httpClient.get<fromModel.AdvisorHirerarchy>(Constant.uri.advisorHierarchy + "?bmEmail=" + '', Utils.getHttpOptions());
  }
  getBMList(): Observable<string[]> {
    return this._httpClient.get<string[]>(Constant.uri.getBMList, Utils.getHttpOptions());
  }

  getReviewChkList(productType: string = ''): fromModel.ReviewChkItem[] {
    if (!productType || productType === '') {
      return this.faReviewChkList;
    } else {
      let _ret: fromModel.ReviewChkItem[] = [];
      this.faReviewChkList.forEach(t => {
        if (t.products.includes(productType)) {
          _ret.push(t);
        }
      });
      return _ret;
    }
  }

  getReviewChkListBuySell(c: string): fromModel.ReviewChkItem[] {
    return this.faReviewChkList.filter(t => t.buySellCode === c.trim().toUpperCase());
  }
  upload(content: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if (content) {
      formData.append('infile', content, content.name);
    }

    return this._httpClient.post<any>(Constant.uri.uploadFile, formData, Utils.getHttpOptionsformdata());
  }

  // -------- Tracing -------------
  // -------- Tracing -------------
  // Information, Verbose, Warning, Error, Critical
  traceMsg(msg: string, detail?: any, level: string = "Information"): void {

    this.step = this.step + 1;
    // this.appInsightsService.trackTrace(this.app + msg, {
    //   'userId': this.user?.userid || '', 'sessionId': this.sessionId, 'step': this.step.toString(),
    //   'datail': JSON.stringify(detail)
    // }, SeverityLevel.Information);
    this.appInsights.trackTrace({ message: this.app + msg }, { 'userId': this.user?.userid, 'sessionId': this.sessionId, 'datail': JSON.stringify(detail) });
    this.flushMessages();
  }

  // traceEvent(err: Error): void {
  //   this.appInsights.trackException({exception:err});
  // }

  traceException(err: Error): void {
    this.appInsights.trackException({ exception: err });
  }

  flushMessages() {
    this.appInsights.flush();
  }
  // -------- Tracing End -------






}
