import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http'
import { Constant } from '../../environments/constant';


@Injectable({
  providedIn: 'root'
})
export class InitService {

  private httpClient: HttpClient
  constructor(private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
   }

  getConfigLocal() {
    return new Promise<any>((resolve, reject) => {
      Constant.updateConfig({   
        "env": "DEV",
        "apibaseurl": "https://localhost:44366/api/",    
        "apibaseurl2": "https://localhost:44378/api/",    
        "clientid": "2e9858f4-6635-44a6-9696-c5384081f05f",//"1962ee87-7409-4b5c-b5be-1b19632b3420"
        "instance": "https://login.microsoftonline.com/",
        "tenant": "rockco.onmicrosoft.com",
        "appinsightkey": "b1286596-73cc-479c-8cd6-8790974ced0f",
        "scope": "https://regbi.dev.rockco.com/default",//"https://orderentry.dev/default",
        "apienvurl":"https://api.dev.rockco.com/",
        "ppturl":"https://ppt.dev.rockco.com/ppthome/document/AAR?account=",
        "superusers":"NGhiwala@rockco.com,Syadav@rockco.com",
        "regbi":"Y"
      });
      resolve(Constant.config);
    }).catch(err => {
      console.log("Error occured while initializing service. Detail: " + JSON.stringify(err));
    });
  }

  getConfig() {
    return new Promise<any>((resolve, reject) => {
      let _url = '/api/config';
      //let _url = "http://localhost:63894/api/config";
      this.httpClient.get(_url).toPromise().then(res => {
        Constant.updateConfig(res as any);
        resolve(res);
      })
    }).catch(err => {
      console.log("Error occured while initializing service. Detail: " + JSON.stringify(err));
    });
  }
}
