<div style="max-height: 500px; overflow: auto;" class="p-10">   
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="p-r-15">
    {{dataSource}}
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled></button>      
      <mat-checkbox class="checklist-leaf-node"
                    [checked]="checklistSelection.isSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node)">{{node.label}}</mat-checkbox>
    </mat-tree-node>
  
    <!-- <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-form-field>
        <input matInput #itemValue placeholder="New item...">
      </mat-form-field>
      <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
    </mat-tree-node> -->
  
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-checkbox [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="todoItemSelectionToggle(node)">{{node.label}}</mat-checkbox>
    
    </mat-tree-node>
  </mat-tree>  
</div>
<div class="center-align-container">
  <button mat-button color="accent" class="p-5" (click)="cancel()">Cancel</button>
  <div class="flex-row-container-right-align">
    <button mat-button color="accent" class="p-5" (click)="uncheckAll()">Clear All</button>  
    <button mat-button  color="primary" class="m-l-10 p-5" (click)="applyFilter()">Apply</button>  
  </div>  
</div>

