import { Component, OnDestroy, OnInit, Inject, ViewChild } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserService, UserDetail } from './services/user.service';
import { Subject, Subscription } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionType, AuthenticationResult, PopupRequest, RedirectRequest, EventMessage, EventType, SilentRequest, EventPayload } from '@azure/msal-browser';
import * as fromModel from './models/models';
import { Constant } from 'src/environments/constant';

import { FormControl, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('advMenuTrigger') advMenuTrigger: MatMenuTrigger | undefined;
  title = 'Order Entry/Reg-BI Attestation';
  isDataMasked = false;
  loggedIn: boolean = false;
  isIframe = false;
  isFirstTimeLoggedin = false;
  private readonly _destroying$ = new Subject<void>();
  jwtHelperSvc: JwtHelperService | undefined;
  user: UserDetail | undefined;
  simRole!: fromModel.USERROLE;
  originalRole!: fromModel.USERROLE;
  advTree: fromModel.TodoItemNode[] = [];
  repIds: string[] = [];
  _urlSub?: Subscription;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,

    private _t: Title) {
    this.title = 'Reg-BI Attestation';
    this.isIframe = window !== window.parent && !window.opener;
    this._urlSub = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // * NavigationEnd: When navigation ends successfully.
        if (event?.url?.includes(Constant.routes.home)) {
          this._t.setTitle('Reg-BI Attestation Home');
          this.title = 'Reg-BI Attestation';
        } else if (event?.url?.includes(Constant.routes.existingorders)) {
          this._t.setTitle('Reg-BI Orders Attestation');
          this.title = 'Reg-BI Orders Attestation';
        } else if (event?.url?.includes(Constant.routes.newAccounts)) {
          this._t.setTitle('Reg-BI New Account Rationale');
          this.title = 'Reg-BI New Account Rationale';
        } else if (event?.url?.includes(Constant.routes.pteRollover)) {
          this._t.setTitle('DOL Rollover Funding');
          this.title = 'DOL Rollover Funding';
        }
      }
    })
  }

  ngOnInit() {
    // //setting app insight key
    // this.appInsightSvc.config = {
    //   instrumentationKey: Constant.config.appInsightKey
    // };
    // this.appInsightSvc.init();

    this.userService.traceMsg("******** RegBI - App - Init ********");
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        if (result.eventType == EventType.LOGIN_SUCCESS || result.eventType == EventType.ACQUIRE_TOKEN_SUCCESS) {
          if (!this.isFirstTimeLoggedin) {
            this.isFirstTimeLoggedin = true;
            this.checkAccount();
            this.getUserName((result.payload as any).idToken);
          }
          this.loggedIn = true;
        } else if (result.eventType == EventType.LOGIN_FAILURE) {
          console.log("Login Failure: " + JSON.stringify(result));
        } else {
          this.authService.loginRedirect();
        }
      });

    //this.userService.getBMList().subscribe();
    this.checkAccount();
    if (!this.loggedIn) {
      this.login();
    } else {
      this.getUserName(localStorage.getItem('msal.idtoken') ?? '');
    }
    this.userService.getBMList().subscribe();
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    if (this.loggedIn) {
      this.getUserName(localStorage.getItem('msal.idtoken') ?? '');
    }
  }

  login() {
    //this.authService.acquireTokenSilent({ scopes: Constant.config.scopes } as SilentRequest);
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            //this.checkAccount();
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            //this.checkAccount();
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  navigateTo(_route: string) {
    this.router.navigateByUrl(_route);
  }
  private getRole(roles: string[]) {
    let role = fromModel.USERROLE.FA;
    if (roles && roles.length > 0) {
      let r: any;

      r = roles.find(_role => _role === "RCM.PMTECH");
      if (r !== undefined) {
        role = fromModel.USERROLE.SUPER;
        return role;
      }

      r = roles.find(_role => _role === "Advisory.IOI.PO");
      if (r !== undefined) {
        role = fromModel.USERROLE.HO;//PO is considerd HO
        return role;
      }

      r = roles.find(_role => _role === "Advisory.OLT");
      if (r !== undefined) {
        role = fromModel.USERROLE.BM;
        return role;
      }

      r = roles.find(_role => _role === "Advisory.Compliance");
      if (r !== undefined) {
        role = fromModel.USERROLE.COMP;
        return role;
      }

      r = roles.find(_role => _role === "Advisory.Supervision");
      if (r !== undefined) {
        role = fromModel.USERROLE.SUPERVISION;
        return role;
      }

      r = roles.find(_role => _role === "Advisory.HomeOffice");
      if (r !== undefined) {
        role = fromModel.USERROLE.HO;
        return role;
      }

      r = roles.find(_role => _role === "Advisory.All");
      if (r !== undefined) {
        role = fromModel.USERROLE.FA;
        return role;
      }
    }
    return role;
  }


  private getUserName(idtoken: string) {
    if (!this.jwtHelperSvc) {
      this.jwtHelperSvc = new JwtHelperService();
    }
    if (idtoken) {
      const decodedToken = this.jwtHelperSvc.decodeToken(idtoken);
      if (!this.userService.getUser()) {
        console.log("***********************************GET USER NAME****************************");
        let _u = new UserDetail();
        _u.name = decodedToken.name || '';
        _u.userid = decodedToken.preferred_username || '';
        _u.poolIds = decodedToken.poolIds || '';
        _u.repId = decodedToken.primaryid || '';

        this.userService.traceMsg("RegBI UI: name: " + _u.name + " T:" + idtoken);
        _u.role = this.getRole(decodedToken?.roles);
        this.initUser(_u);


        // _u.role = fromModel.USERROLE.FA;
        // if (Constant.config.superUsers && Constant.config.superUsers.length && (Constant.config.superUsers as string[]).includes(_u.userid.toLocaleUpperCase())) {
        //   _u.role = fromModel.USERROLE.SUPER;
        //   this.initUser(_u);
        // } else if (_u.poolIds) {
        //   const _arrPoolIds = _u.poolIds.split(",");
        //   if (_arrPoolIds.includes("000")) {
        //     _u.role = fromModel.USERROLE.HO;
        //     this.initUser(_u);
        //   } else {            
        //     this.userService.getBMList().subscribe(d => {              
        //       this.userService.traceMsg('BM List info received: Detail' + JSON.stringify(d));
        //       if (d && d.length && d.includes(_u.userid.toUpperCase())) {
        //         _u.role = fromModel.USERROLE.BM;
        //       }
        //       this.initUser(_u);
        //     }, err => {
        //       this.userService.traceMsg('Error occured while getting advisor info. Detail:' + JSON.stringify(err), 'Error');
        //       this.initUser(_u);
        //     });
        //   }
        // } else {
        //   this.initUser(_u);
        // }
      }
    }
  }

  private getAdvTree() {
    this.userService.getAdvisors(this.user?.role === fromModel.USERROLE.BM ? this.user.userid : '',
      this.user?.role === fromModel.USERROLE.FA ? true : false).subscribe(adv => {
        if (adv && adv.regions && adv.regions.length) {
          this.prepareAdvTree(adv);
        }
      }, err => {
        this.userService.traceMsg('Error getting advisors data.', err, "Error");
      });
  }

  private prepareAdvTree(adv: fromModel.AdvisorHirerarchy) {
    let _ct = 1;
    let _reps: string[] = [];
    adv.regions.forEach(r => {
      let _r = { id: "R" + _ct.toString(), isChecked: true, label: r.regName, children: [] } as fromModel.TodoItemNode;
      _ct++;
      r.hubs.forEach(h => {
        let _h = { id: "R" + _ct.toString(), isChecked: true, label: h.hubName, children: [] } as fromModel.TodoItemNode;
        _r.children.push(_h);
        _ct++;
        h.teams.forEach(t => {
          let _t = { id: "T" + _ct.toString(), isChecked: true, label: t.teamName, children: [] } as fromModel.TodoItemNode;
          _h.children.push(_t);
          _ct++;
          t.advisors.forEach(a => {
            let _a = { id: a.repId, isChecked: true, label: a.repId + " - " + a.repName } as fromModel.TodoItemNode;
            _t.children.push(_a);
            _reps.push(a.repId);
          })
        });
      });
      this.advTree.push(_r);
    });
    if (_reps.length && this.user && this.user.role === fromModel.USERROLE.BM) {
      this.advListChanged(_reps);
    }
  }
  /*
<app-advTreeFilter 
              [AdvisorsTreeData]="advTree" 
            [SelectedRepIds]="repIds"  
            (OnAppliedFilter)="advListChanged($event)"
              >
          </app-advTreeFilter>
  Change line 297 prepareAdvTree1 -> prepareAdvTree
  Change line 160 prepareAdvTree -> prepareAdvTree1
  */
  private prepareAdvTree1(adv: fromModel.AdvisorHirerarchy) {
    let advisorsTreeData: any = [];
    let id = 1;
    let code = '0.1';

    //regions
    adv.regions.forEach(r => {
      let region = { text: r.regName, code: code.toString(), isChecked: false, id: "R" + id.toString(), 'color': '#004377' }
      advisorsTreeData.push(region);
      id++;

      //hubs
      r.hubs.forEach((h, hi) => {
        let hub = { text: h.hubName, code: `${code.toString()}.${hi + 1}`, isChecked: false, id: "H" + id.toString(), 'color': '#5c60b7' }
        advisorsTreeData.push(hub);
        id++;

        //teams
        h.teams.forEach((t, ti) => {
          let team = { text: t.teamName, code: `${hub.code}.${ti + 1}`, isChecked: false, id: "T" + id.toString(), 'color': '#8A39E1' }
          advisorsTreeData.push(team);
          id++;

          //advisors
          t.advisors.forEach((a, ai) => {
            let advisor = { text: `${a.repId} - ${a.repName}`, code: `${team.code}.${ai + 1}`, isChecked: false, id: a.repId, 'color': 'black' }
            advisorsTreeData.push(advisor);
            id++;

          });
        });
      });
      code = (Number(code) + 0.1).toFixed(1);
    });
    this.advTree = advisorsTreeData;
    console.log(advisorsTreeData);
    if (advisorsTreeData.length && this.user && this.user.role === fromModel.USERROLE.BM) {
      this.advListChanged(advisorsTreeData);
    }
    // this.advListChanged(advisorsTreeData);
    // this.advisorsTreeData = advisorsTreeData;
  }
  private initUser(_u: UserDetail) {
    this.user = _u;
    if (this.user.role !== fromModel.USERROLE.BM) {
      //for BM use is getting set in getAdvTree method
      this.userService.setUser(_u);
    }
    this.originalRole = _u.role;
    this.simRole = _u.role;
    this.getAdvTree();
  }


  changeUser() {
    if (this.user) {
      if (this.simRole as any === 'CONTROL') {
        this.user.role = fromModel.USERROLE.SUPERVISION;
      } else {
        this.user.role = this.simRole;
      }
      this.userService.setUser(this.user);
    }
  }

  advListChanged(_repIds: string[]) {
    if (this.user) {
      this.user.simulateRepIds = _repIds && _repIds.length ? _repIds.join(',') : '';
      this.repIds = _repIds;
      this.userService.setUser(this.user);
      this.closeFilterMenu();
    }
  }

  closeFilterMenu() {
    if (this.advMenuTrigger) {
      this.advMenuTrigger.closeMenu();
    }
  }

  get showUserSel() {
    /*
    export enum USERROLE {
        NULL = "NULL",
        FA = "FA",
        BM = "BM",
        HO = "HO",
        OPS = "OPS",
        SUPER = "SUPER",
        PO = 'PO',
        COMP = 'COMP',
        SUPERVISION = 'SUPERVISION'
    }
    */
    // IN QA AND DEV, FA and BM won't be allowed to show role selector, everyone will have access to role selector.
    if (Constant.config.env === 'QA' || Constant.config.env === 'DEV') {
      return this.user &&
        (this.originalRole !== fromModel.USERROLE.FA && this.originalRole !== fromModel.USERROLE.BM) &&
        (this.router.url?.toLowerCase() === '/new-accounts' || this.router.url?.toLowerCase() === '/pte-rollover' ||
          this.router.url?.toLowerCase() === '/existing-ordersv2' || this.router.url?.toLowerCase() === '/existing-orders')
    } else if (Constant.config.env === 'PROD') {
      // OLD: When in prod, Only Super user can see this role selector.
      // NEW: 5-22: as per john, they are accepting responsibility for enabling role selector in prod for non FA/BM users
      return this.user &&
        (this.originalRole !== fromModel.USERROLE.FA && this.originalRole !== fromModel.USERROLE.BM) &&
        (this.router.url?.toLowerCase() === '/new-accounts' || this.router.url?.toLowerCase() === '/pte-rollover' ||
          this.router.url?.toLowerCase() === '/existing-ordersv2' || this.router.url?.toLowerCase() === '/existing-orders')
    } else {
      return false;
    }

  }
}
