import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { __decorate } from 'tslib';
import * as fromModel from 'src/app/models/models';
import { UserService } from './user.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

constructor(
  private userSvc: UserService  
) { }


async generateOrdersExcel(data:any[]) {
  // Excel Title, Header, Data
  const title = 'Orders';
  const header = ['Id', 'Account','Account Type', 'Emp. Affiliated', 'Cusip', 'Product Desc', 'Product Type', 'Quantity','Expected Price','Notional Val.','Sales Concession',
    'Total Selling Concession',  'Other Fee','Notional Other Fee','Total Fee','Solicited/Unsolicited','Account Advisor', 'Tran. Date','Status',
  'Review Date','Order By','Order Date', 'Order Time','Client Email','Branch Managers','Team Name'];

  // Create workbook and worksheet
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Orders');

  // Add Title Row
  const titleRow = worksheet.addRow([title]);

  // Blank Row
  worksheet.addRow([]);

  // Header Row
  const headerRow = worksheet.addRow(header);

  // Data row
  data.forEach(d => {
    const row = worksheet.addRow(d);
  });

  // Generate Excel File with given name
  workbook.xlsx.writeBuffer().then((data: any) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    fs.saveAs(blob, 'Orders-' + (new Date()).getTime() + '.xlsx');
  });
}


}
