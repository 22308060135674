import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { Constant } from '../../environments/constant';
import { Observable, from, of } from 'rxjs';
import { Utils } from 'src/utils/utils';
import * as fromModel from '..//models/models';

@Injectable({
  providedIn: 'root'
})
export class ExistingOrderService {

  constructor(private _httpClient: HttpClient, private _userService: UserService) { }

  getOrders(c:fromModel.SearchCriteria): Observable<fromModel.ExistingOrdersResponse> {
    return this._httpClient.post<fromModel.ExistingOrdersResponse>(Constant.uri.getExistingOrders, c, Utils.getHttpOptions());
  }

  updateOrderStatus(o:fromModel.ExistingOrder):Observable<fromModel.ExistingOrder>{
    return this._httpClient.post<fromModel.ExistingOrder>(Constant.uri.updateExistingOrders,o,Utils.getHttpOptions())
  }

  getTrades(c:string): Observable<fromModel.Trade[]> {
    return this._httpClient.get<fromModel.Trade[]>(Constant.uri.getTrades + "?orderId=" + c, Utils.getHttpOptions());
  }  

  getFacets(payload: fromModel.SearchCriteria): Observable<fromModel.ExistingOrdersResponseV2> {
    return this._httpClient.post<fromModel.ExistingOrdersResponseV2>(Constant.uri.getExistingOrdersV2, payload, Utils.getHttpOptions());
  }

  getExistingOrders(payload: fromModel.SearchCriteria): Observable<fromModel.ExistingOrdersResponseV2> {
    return this._httpClient.post<fromModel.ExistingOrdersResponseV2>(Constant.uri.getExistingOrdersV2, payload, Utils.getHttpOptions());
  }
}


