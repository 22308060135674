import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { Constant } from '../../environments/constant';
import { Observable, from, of } from 'rxjs';
import { Utils } from 'src/utils/utils';
import * as fromModel from '../models/models';
// import 'rxjs/add/observable/of';
// import 'rxjs/add/operator/delay';
import dol from '../../assets/workflows/dol.json';
import funding from '../../assets/workflows/funding.json';
import newacct from '../../assets/workflows/newacct.json';
import transition from '../../assets/workflows/transition.json';
import newAccount529 from '../../assets/workflows/529.json';

import ORDER_AI_BUY from '../../assets/workflows/ORDER_AI_BUY.json';
import ORDER_AI_SELL from '../../assets/workflows/ORDER_AI_SELL.json';
import ORDER_UT_BUY from '../../assets/workflows/ORDER_UT_BUY.json';
import ORDER_UT_SELL from '../../assets/workflows/ORDER_UT_SELL.json';
import ORDER_SP_SELL from '../../assets/workflows/ORDER_SP_SELL.json';
import ORDER_SP_BUY from '../../assets/workflows/ORDER_SP_BUY.json';
import ORDER_MF_BUY from '../../assets/workflows/ORDER_MF_BUY.json';
import ORDER_MF_SELL from '../../assets/workflows/ORDER_MF_SELL.json';
import ORDER_CM_BUY from '../../assets/workflows/ORDER_CM_BUY.json';
import ORDER_CM_SELL from '../../assets/workflows/ORDER_CM_SELL.json';
import ORDER_529 from '../../assets/workflows/ORDER_529.json';

import ORDER_AI_BUY_RET from '../../assets/workflows/RET_ORDER_AI_BUY.json';
import ORDER_AI_SELL_RET from '../../assets/workflows/RET_ORDER_AI_SELL.json';
import ORDER_UT_BUY_RET from '../../assets/workflows/RET_ORDER_UT_BUY.json';
import ORDER_UT_SELL_RET from '../../assets/workflows/RET_ORDER_UT_SELL.json';
import ORDER_SP_SELL_RET from '../../assets/workflows/RET_ORDER_SP_SELL.json';
import ORDER_SP_BUY_RET from '../../assets/workflows/RET_ORDER_SP_BUY.json';
import ORDER_MF_BUY_RET from '../../assets/workflows/RET_ORDER_MF_BUY.json';
import ORDER_MF_SELL_RET from '../../assets/workflows/RET_ORDER_MF_SELL.json';
import ORDER_CM_BUY_RET from '../../assets/workflows/RET_ORDER_CM_BUY.json';
import ORDER_CM_SELL_RET from '../../assets/workflows/RET_ORDER_CM_SELL.json';
import ORDER_529_RET from '../../assets/workflows/RET_ORDER_529.json';


@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  PENDING: fromModel.NewAccountQuestion[] = [];
  newAccountWfBM: fromModel.NewAccountQuestion[] = [];
  newAccountWfOPS: fromModel.NewAccountQuestion[] = [];
  private RETURNEDBM: fromModel.NewAccountQuestion[] = [];

  constructor(private _httpClient: HttpClient) {
    this.setWorkflowList(fromModel.NEWACCT);
  }

  getPreviousResponses(payload: any): Observable<any> {
    // return this._httpClient.get<any>(Constant.uri.getPreviousResponses + '/' + reviewId, Utils.getHttpOptions());
    return this._httpClient.post<any>(Constant.uri.getPreviousResponses, payload, Utils.getHttpOptions());
  }

  attestNewAccounts(payload: fromModel.NewAccountAttestPayload): Observable<any> {
    return this._httpClient.post<any>(Constant.uri.attestNewAccounts, payload, Utils.getHttpOptions());
  }


  setWorkflowList(id: string, subQualifier = undefined) {
    
    let sets: fromModel.QuestionSet[] = [];
    if (id === fromModel.DOL) {
      sets = dol;
    } else if (id === fromModel.FUNDING) {
      sets = funding;
    } else if (id === fromModel.NEWACCT) {
      sets = newacct;
    } else if (id === fromModel.TRANSITION) {
      sets = transition;
    } else if (id === fromModel.NEWACCT_529) {
      sets = newAccount529;
    }
    else if(id.indexOf("_RET")!= -1){
      switch(id){
        case fromModel.ORDER_AI_BUY_RET : sets = ORDER_AI_BUY_RET;break;
        case fromModel.ORDER_AI_SELL_RET : sets = ORDER_AI_SELL_RET;break;
        case fromModel.ORDER_UT_BUY_RET : sets = ORDER_UT_BUY_RET; break;
        case fromModel.ORDER_UT_SELL_RET : sets = ORDER_UT_SELL_RET; break;
        case fromModel.ORDER_SP_SELL_RET: sets = ORDER_SP_SELL_RET; break;
        case fromModel.ORDER_SP_BUY_RET : sets = ORDER_SP_BUY_RET; break;
        case fromModel.ORDER_MF_BUY_RET : sets = ORDER_MF_BUY_RET; break;
        case fromModel.ORDER_MF_SELL_RET : sets = ORDER_MF_SELL_RET; break;
        case fromModel.ORDER_CM_BUY_RET : sets = ORDER_CM_BUY_RET; break;
        case fromModel.ORDER_CM_SELL_RET : sets = ORDER_CM_SELL_RET; break;
        case fromModel.ORDER_529_RET : sets = ORDER_529_RET; break;
        default : console.error('Workflows Id not found.'); break;      
      }
    }
    else{      
      if (id === fromModel.ORDER_AI_BUY || id === fromModel.ORDER_AI_BUY_NONRET) {
        sets = ORDER_AI_BUY;
      } else if (id === fromModel.ORDER_AI_SELL || id === fromModel.ORDER_AI_SELL_NONRET) {
        sets = ORDER_AI_SELL;
      } else if (id === fromModel.ORDER_UT_BUY || id === fromModel.ORDER_UT_BUY_NONRET) {
        sets = ORDER_UT_BUY;
      } else if (id === fromModel.ORDER_UT_SELL || id === fromModel.ORDER_UT_SELL_NONRET) {
        sets = ORDER_UT_SELL;
      } else if (id === fromModel.ORDER_SP_SELL || id === fromModel.ORDER_SP_SELL_NONRET) {
        sets = ORDER_SP_SELL;
      } else if (id === fromModel.ORDER_SP_BUY || id === fromModel.ORDER_SP_BUY_NONRET) {
        sets = ORDER_SP_BUY;
      } else if (id === fromModel.ORDER_MF_BUY || id === fromModel.ORDER_MF_BUY_NONRET) {
        sets = ORDER_MF_BUY;
      } else if (id === fromModel.ORDER_MF_SELL || id === fromModel.ORDER_MF_SELL_NONRET) {
        sets = ORDER_MF_SELL;
      } else if (id === fromModel.ORDER_CM_BUY || id === fromModel.ORDER_CM_BUY_NONRET) {
        sets = ORDER_CM_BUY;
      } else if (id === fromModel.ORDER_CM_SELL || id === fromModel.ORDER_CM_SELL_NONRET) {
        sets = ORDER_CM_SELL;
      } else if (id === fromModel.ORDER_529 || id === fromModel.ORDER_529_NONRET) {
        sets = ORDER_529;
      } else {
        console.error('Workflows Id not found.')
      }
    }
    this.RETURNEDBM = sets?.find(set => set.statusFor === 'RETURNEDBM')?.questions as any;
    this.newAccountWfOPS = sets?.find(set => set.statusFor === 'CLOSED')?.questions as any;
    this.newAccountWfBM = sets?.find(set => set.statusFor === 'SUBMITTED')?.questions as any;
    this.PENDING = sets?.find(set => set.statusFor === 'PENDING')?.questions as any;

  }


  getPENDING_NO_T() {
    return JSON.parse(JSON.stringify(this.PENDING));
  }

  getSUBMITTED() {
    return JSON.parse(JSON.stringify(this.newAccountWfBM));
  }

  getRETURNEDBM_NT() {
    return JSON.parse(JSON.stringify(this.RETURNEDBM));
  }

  getCLOSED() {
    return JSON.parse(JSON.stringify(this.newAccountWfOPS));
  }

}