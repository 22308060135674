import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { Utils } from 'src/utils/utils';
import * as fromModels from '../models/models';
import { Constant } from 'src/environments/constant';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';


import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';


@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<fromModels.TodoItemNode[]>([]);

  get data(): fromModels.TodoItemNode[] { return this.dataChange.value; }

  constructor() {
    //this.initialize();
  }

  initialize(TREE_DATA:any) {
    // Build the tree nodes from Json object. The result is a list of `fromModels.fromModels.TodoItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `fromModels.fromModels.TodoItemNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): fromModels.TodoItemNode[] {
    return Object.keys(obj).reduce<fromModels.TodoItemNode[]>((accumulator, key) => {
      const item = obj[key];
      const node = new fromModels.TodoItemNode();
      node.label = obj[key].label;
      node.id = obj[key].id;
      node.isChecked=  obj[key].isChecked;      
      if (item != null) {
        if (typeof item === 'object'  && item.children!= undefined) { 
       

          node.children = this.buildFileTree(item.children, level + 1);
        } else {
          node.label = item.label;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: fromModels.TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({label: name} as fromModels.TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: fromModels.TodoItemNode, name: string) {
    node.label = name;
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-advTreeFilter',
  templateUrl: './advTreeFilter.component.html',
  styleUrls: ['./advTreeFilter.component.scss'],
  providers: [ChecklistDatabase]
})
export class AdvTreeFilterComponent implements OnInit {
  @Input('advTreeData')
  advTreeData!: any;
  @Input('selectedRep')
  selectedRep!: string[];
  @Output() onFilterChanged: EventEmitter<string[]> = new EventEmitter();
  @Output() cancelFilter: EventEmitter<any> = new EventEmitter();
  
  flatNodeMap = new Map<fromModels.TodoItemFlatNode, fromModels.TodoItemNode>();
  nestedNodeMap = new Map<fromModels.TodoItemNode, fromModels.TodoItemFlatNode>();
  selectedParent: fromModels.TodoItemFlatNode | null = null;
  newItemName = '';
  treeControl!: FlatTreeControl<fromModels.TodoItemFlatNode>;
  treeFlattener!: MatTreeFlattener<fromModels.TodoItemNode, fromModels.TodoItemFlatNode>;
  dataSource!: MatTreeFlatDataSource<fromModels.TodoItemNode, fromModels.TodoItemFlatNode>;
  checklistSelection = new SelectionModel<fromModels.TodoItemFlatNode>(true /* multiple */);
  initDone = false;
  constructor(
    private userSvc: UserService, private _database: ChecklistDatabase) {

  }

  ngOnInit() {    
    this._database.initialize(this.advTreeData);
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<fromModels.TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this._database.dataChange.subscribe(data => {
      this.dataSource.data = data;
      if(!this.initDone){
        this.initDone = true;
        this.initialCheck();        
      }      
    });
  }

  initialCheck(){    
      if(this.selectedRep && this.selectedRep.length){
        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
          if(this.selectedRep.includes(this.treeControl.dataNodes[i].id))
          this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
        }
      }
      else
      {
        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
          if(this.treeControl.dataNodes[i].isChecked){
            this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
          }          
        }        
      }    
  }
  checkAll(){
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      this.checklistSelection.select(this.treeControl.dataNodes[i]);
      //this.treeControl.expand(this.treeControl.dataNodes[i]);
    }
  }

  uncheckAll(){

    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      this.checklistSelection.deselect(this.treeControl.dataNodes[i]);
      //this.treeControl.expand(this.treeControl.dataNodes[i]);
    }
  }

   GetCheckAll(){

    console.log( this.dataSource.data );
      // if( this.treeFlattener.flattenNodes[0].check) console.log(this.treeControl.dataNodes[i].id);
 
     
    // for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
     
    //   if(this.treeControl.dataNodes[i].isChecked) console.log(this.treeControl.dataNodes[i].id);

    // if(this.treeControl.dataNodes[i].isChecked){
    //   console.log('---------------------------------------------');
    //     console.log(this.treeControl.dataNodes[i].id);
    //     console.log(this.treeControl.dataNodes[i].claimId);

    // }
   // }
  }

  
  getLevel = (node: fromModels.TodoItemFlatNode) => node.level;

  isExpandable = (node: fromModels.TodoItemFlatNode) => node.expandable;

  getChildren = (node: fromModels.TodoItemNode): fromModels.TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: fromModels.TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: fromModels.TodoItemFlatNode) => _nodeData.label === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: fromModels.TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.label === node.label
        ? existingNode
        : new fromModels.TodoItemFlatNode();
    flatNode.label = node.label;
    flatNode.level = level;
    flatNode.id=node.id;
     flatNode.isChecked = node.isChecked;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: fromModels.TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
     this.checklistSelection.isSelected(child))
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: fromModels.TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: fromModels.TodoItemFlatNode): void {
  
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: fromModels.TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    node.isChecked ?  node.isChecked=false:node.isChecked=true; 
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: fromModels.TodoItemFlatNode): void {
    let parent: fromModels.TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: fromModels.TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: fromModels.TodoItemFlatNode): fromModels.TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: fromModels.TodoItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this._database.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: fromModels.TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, itemValue);
  }

  applyFilter(){      
    if(this.checklistSelection.selected.length){
      let _selectedReps:string[] = [];
      (this.checklistSelection.selected.filter(t => !t.expandable) || []).forEach(x => {
        _selectedReps.push(x.id);
      });
      this.onFilterChanged.emit(_selectedReps);
    } else {
      this.onFilterChanged.emit([]);
    }    
  }

  cancel(){   
    this.cancelFilter.emit(null); 
  }  
}
