import { environment } from './environment';
import * as fromModel from './../app/models/models';
export class Constant {
    static routes = {
        home:'home',        
        existingorders:'existing-orders',
        newAccounts: 'new-accounts',
        pteRollover: 'pte-rollover',
        existingordersV2:'existing-ordersv2',

    };

    //Initialization
    static config: any = {};
    static uri = {
        reference: '',
        productlist: '',
        getlogs: '',
        actSearch: '',
        clientSearch: '',
        advSearch: '',
        investmentsAPI: '',
        saveOrder: '',
        getOrders: '',
        deleteOrder: '',
        saveProduct: '',
        getProducts: '',
        deleteProduct: '',
        getBMList: '',
        advisorHierarchy: '',
        getExistingOrders:'',
        updateExistingOrders:'',
        getTrades:'',
        importOrders:'',
        orderQueueStatus:'',
        getImportOrders:'',
        sendProspectus:'',
        searchProspectusQueue:'',
        uploadFile:'',
        getNewAccounts: '',
        attestNewAccounts: '',
        getPreviousResponses: '',
        getPTERolloverAccounts: '',
        getExistingOrdersV2: '',
        downloadFile:'',
        getPTETransactions: ''
    };

    static productTypes: fromModel.CodeValue[] =
        [{ code: 'AI', value: 'Alternative Investment' },
        { code: 'SP', value: 'Structured Product', selected:true },
        { code: 'AN', value: 'Annuity' },
        { code: 'PREF', value: 'Preferreds', selected:true },
        { code: 'CEF', value: 'CEF', selected:true },
        { code: 'SPAC', value: 'SPAC', selected:true }];

    static nonComplexProductCodes:string[] = ["PREF","CEF","SPAC"];

    static updateConfig = ((configvalues: any) => {

        Constant.config.clientId = configvalues.clientid;
        Constant.config.instance = configvalues.instance;
        Constant.config.tenant = configvalues.tenant;
        Constant.config.appInsightKey = configvalues.appinsightkey;
        Constant.config.scope = configvalues.scope;
        Constant.config.scopes = configvalues.scope.split(',');
        Constant.config.autority = configvalues.instance + configvalues.tenant;
        Constant.config.superUsers = configvalues.superusers ? (<string>configvalues.superusers).toLocaleUpperCase().split(',') : [];
        Constant.config.pptUrl = configvalues.ppturl;
        Constant.config.regbi = configvalues.regbi;
        Constant.config.env = configvalues.env;

        Constant.config.apiBaseUrl = configvalues.apibaseurl;
        Constant.config.apiBaseUrl2 = configvalues.apibaseurl2;
        Constant.config.apiEnvUrl = configvalues.apienvurl;

        
        // Constant.uri.getBMList = `${Constant.config.apiEnvUrl}pm/api/Advisors/branchmanagers`;        
        Constant.uri.actSearch = `${Constant.config.apiEnvUrl}search/accounts`;
        Constant.uri.advSearch = `${Constant.config.apiEnvUrl}search/advisors`;        
        Constant.uri.clientSearch = `${Constant.config.apiEnvUrl}search/clients`;
        // Constant.uri.advisorHierarchy = `${Constant.config.apiEnvUrl}pm/api/Advisors/AdvisorHirerarchy`;        
        Constant.uri.getlogs = `${Constant.config.apiBaseUrl}Instrumentation/PMlog/List`;        
        Constant.uri.getExistingOrders = `${Constant.config.apiBaseUrl2}RegBI/list`;
        Constant.uri.updateExistingOrders = `${Constant.config.apiBaseUrl2}RegBI/updateStatus`;
        Constant.uri.getTrades = `${Constant.config.apiBaseUrl2}RegBI/trades`;
        // Didn't change it in apiBaseURL because all other services are using 44366 for 
        Constant.uri.getNewAccounts = `${Constant.config.apiBaseUrl2}RegBI/new-accounts-list`;
        Constant.uri.attestNewAccounts = `${Constant.config.apiBaseUrl2}RegBI/attest-accounts`;
        Constant.uri.getPreviousResponses = `${Constant.config.apiBaseUrl2}RegBI/get-previous-responses`;
        Constant.uri.getPTERolloverAccounts = `${Constant.config.apiBaseUrl2}RegBI/pte-accounts-list`;
        Constant.uri.downloadFile = `${Constant.config.apiBaseUrl}document/downloadFile`;
        Constant.uri.uploadFile = `${Constant.config.apiBaseUrl}document/uploadFile`;
        Constant.uri.getExistingOrdersV2 = `${Constant.config.apiBaseUrl2}RegBI/listV2`;
        Constant.uri.getPTETransactions = `${Constant.config.apiBaseUrl2}RegBI/get-pte-transactions`;

        Constant.uri.getBMList = `${Constant.config.apiBaseUrl2}Advisors/branchmanagers`;
        Constant.uri.advisorHierarchy = `${Constant.config.apiBaseUrl2}Advisors/AdvisorHirerarchy`;

    });


}
