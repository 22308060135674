import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { Constant } from '../../environments/constant';
import { Observable, from, of } from 'rxjs';
import { Utils } from 'src/utils/utils';
import * as fromModel from '../models/models';
// import 'rxjs/add/observable/of';
// import 'rxjs/add/operator/delay';

@Injectable({
  providedIn: 'root'
})
export class PTERolloverService {
  PENDING: fromModel.NewAccountQuestion[] = [];
  newAccountWfBM: fromModel.NewAccountQuestion[] = [];
  newAccountWfOPS: fromModel.NewAccountQuestion[] = [];

  constructor(private _httpClient: HttpClient) { }

  upload(content: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if (content) {
      formData.append('infile', content, content.name);
    }
    //const _uri = "https://localhost:44366/api/document/uploadfile" + "?folder=supervision"   ;
    const _uri = Constant.uri.uploadFile + "?folder=regbi";
    return this._httpClient.post<any>(_uri, formData, Utils.getHttpOptionsformdata());
  }

  downloadfile(docId: string | undefined): Observable<Blob> {
    let _options = {
      headers: new HttpHeaders({
        responseType: 'blob'
      }),
      responseType: 'blob'
    } as any;
    //const _uri = "https://localhost:44366/api/document/DownloadFile" + "?folder=supervision&fname=" + docId;
    const _uri = Constant.uri.downloadFile + "?folder=regbi&fname=" + docId;
    return this._httpClient.get(_uri, { responseType: 'blob' })
  }


  getFacets(payload: fromModel.SearchCriteria): Observable<fromModel.NewAccountsResponse> {
    return this._httpClient.post<fromModel.NewAccountsResponse>(Constant.uri.getPTERolloverAccounts, payload, Utils.getHttpOptions());
  }

  getPTEAccounts(payload: fromModel.SearchCriteria) {
    return this._httpClient.post<fromModel.NewAccountsResponse>(Constant.uri.getPTERolloverAccounts, payload, Utils.getHttpOptions());
  }

  // getTrades(payload: fromModel.SearchCriteria) {
  //   return this._httpClient.post<any>(Constant.uri.getExistingOrdersV2, payload, Utils.getHttpOptions());
  // }

  getPTETransactions(c?: string): Observable<any[]> {
    return this._httpClient.get<any[]>(Constant.uri.getPTETransactions + "?reviewKey=" + c, Utils.getHttpOptions());
  }


}